import { useState, useEffect } from "react";
import { Form, Row, Col, Modal, Stack } from "react-bootstrap";
import { Field, Formik, Form as FormikForm } from "formik";
import * as yup from "yup";
import { useSessionStore } from "../../../../Stores/SessionStore";
import AlertModal from "../../../Core/AlertModal";

export default function AddressModal({show, onClose, address}) {
    const store = useSessionStore();

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [saving, setSaving] = useState(false);
    const [builds, setBuilds] = useState([]);
    const [details, setDetails] = useState({
        ERF: "",
        StreetAddress: "",
        MDUName: "",
        MDUBlock: "",
        MDUUnitNumber: "",
        Township: "",
        PropertyType: "",
        POP: "",
        BuildId: null
    });

    useEffect(() => {
        getBuilds();
    }, []);

    useEffect(() => {
        
        if (address) {
            setDetails(address);
        } else {
            setDetails({
                ERF: "",
                StreetAddress: "",
                MDUName: "",
                MDUBlock: "",
                MDUUnitNumber: "",
                Township: "",
                PropertyType: "",
                POP: "",
                BuildId: null
            });
        }
    }, [address]);

    const getBuilds = async () => {
        
        const result = await store.AddressesService.GetBuilds();

        if (result && result.Success) {
            setBuilds(result.Data);
        }
    }

    const handleSubmit = async (data) => { 

        setSaving(true);

        data.StreetAddress = data.StreetAddress.toUpperCase();
        data.MDUName = data.MDUName.toUpperCase();
        data.MDUBlock = data.MDUBlock.toUpperCase();
        data.Township = data.Township.toUpperCase();
        data.POP = data.POP.toUpperCase();
        data.ERF = data.ERF.toUpperCase();

        if (address) {
            data.Id = address.Id;

            const result = await store.AddressesService.UpdateAddress(data);

            if (result && result.Success) {
                setSuccessMessage("Address updated successfully");
                setShowAlert(true);
            } else {
                setAlertMessage(result && result.Message ? result.Message : "An error occurred while updating the address");
                setShowAlert(true);
            }
            setSaving(false);
            return;
        }
        
        const result = await store.AddressesService.CreateAddress(data);

        if (result && result.Success) {
            onClose(true);
        } else {
            setAlertMessage(result && result.Message ? result.Message : "An error occurred while adding the address");
            setShowAlert(true);
        }
        setSaving(false);
    }

    const validationSchema = yup.object().shape({
		ERF: yup.string().required("ERF is required"),
        StreetAddress: yup.string().required("Street Address is required"),
        MDUUnitNumber: yup.string().when("PropertyType", {
            is: "SDU",
            then: yup.string().notRequired(),
            otherwise: yup.string().required("MDU Unit Number is required")
        }),
        MDUName: yup.string().when("PropertyType", {
            is: (val) => val === "SDU" || val === "Informal MDU",
            then: yup.string().notRequired(),
            otherwise: yup.string().required("MDU Unit Number is required")
        }),
        Township: yup.string().required("Township is required"),
        PropertyType: yup.string().required("Property Type is required"),
        POP: yup.string().required("POP is required"),
	}).required();

    return (
        <Modal show={ show } dialogClassName="modal-address">
            <Modal.Header className="modal-admin-header">
                <div className="modal-admin-heading">{`${address ? "Edit" : "Add"} Address`}</div>
            </Modal.Header>
            <Formik initialValues={details} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize={true}>
                {({ errors, touched, values }) => (
                    <FormikForm>
                        <Modal.Body>
                            <Stack gap={3}>
                                <Row>
                                <Col className="col-12 col-xl-6 mb-3 mb-xl-0">
                                        <Stack gap={3}>                                        
                                            <Form.Group as={Row}>
                                                <Col className="col-3 d-flex align-items-center">
                                                    <Form.Label>Property Type</Form.Label>
                                                </Col>
                                                <Col className="col-9">
                                                    <Field isInvalid={touched.PropertyType && errors.PropertyType } label="Property Type" name="PropertyType" as={Form.Select}>                                                            
                                                        <option value="">Please select a Property Type</option>
                                                        <option value="SDU">SDU</option>
                                                        <option value="MDU">MDU</option>
                                                        <option value="Informal MDU">Informal MDU</option>
                                                    </Field>
                                                    {
                                                        touched.PropertyType && errors.PropertyType && 
                                                        <small className="text-danger">{ errors.PropertyType }</small>
                                                    }
                                                </Col>
                                            </Form.Group>
                                        </Stack>
                                    </Col>
                                    <Col className="col-12 col-xl-6 mb-3 mb-xl-0">
                                        <Stack gap={3}>                                        
                                            <Form.Group as={Row}>
                                                <Col className="col-3 d-flex align-items-center">
                                                    <Form.Label>Erf</Form.Label>
                                                </Col>
                                                <Col className="col-9">
                                                    <Field isInvalid={errors.ERF } label="Erf" name="ERF"  as={Form.Control}></Field>
                                                            {
                                                                errors.ERF && 
                                                                <small className="text-danger">{ errors.ERF }</small>
                                                            }
                                                </Col>
                                            </Form.Group>
                                        </Stack>
                                    </Col>                                                
                                </Row>
                                <Row>
                                    <Col className="col-12 col-xl-6 mb-3 mb-xl-0">
                                        <Stack gap={3}>                                        
                                            <Form.Group as={Row}>
                                                <Col className="col-3 d-flex align-items-center">
                                                    <Form.Label>Street Address</Form.Label>
                                                </Col>
                                                <Col className="col-9">
                                                    <Field isInvalid={errors.StreetAddress } label="Street Address" name="StreetAddress"  as={Form.Control}></Field>
                                                    {
                                                        errors.StreetAddress &&
                                                        <small className="text-danger">{ errors.StreetAddress }</small>
                                                    }
                                                </Col>
                                            </Form.Group>
                                        </Stack>
                                    </Col>
                                    <Col className={`col-12 col-xl-6 mb-3 mb-xl-0 ${values.PropertyType === "SDU" ? "d-none" : ""}`}>
                                        <Stack gap={3}>                                        
                                            <Form.Group as={Row}>
                                                <Col className="col-3 d-flex align-items-center">
                                                    <Form.Label>MDU Name</Form.Label>
                                                </Col>
                                                <Col className="col-9">
                                                    <Field isInvalid={errors.MDUName } label="MDU Name" name="MDUName" as={Form.Control}></Field>
                                                            {
                                                                errors.MDUName &&
                                                                <small className="text-danger">{ errors.Erf }</small>
                                                            }
                                                </Col>
                                            </Form.Group>
                                        </Stack>
                                    </Col>                                                
                                </Row>
                                <Row  className={`${values.PropertyType === "SDU" ? "d-none" : ""}`}>
                                    <Col className="col-12 col-xl-6 mb-3 mb-xl-0">
                                        <Stack gap={3}>                                        
                                            <Form.Group as={Row}>
                                                <Col className="col-3 d-flex align-items-center">
                                                    <Form.Label>MDU Block</Form.Label>
                                                </Col>
                                                <Col className="col-9">
                                                    <Field isInvalid={errors.MDUBlock } label="MDU Block" name="MDUBlock" as={Form.Control}></Field>
                                                    {
                                                        errors.MDUBlock &&
                                                        <small className="text-danger">{ errors.MDUBlock }</small>
                                                    }
                                                </Col>
                                            </Form.Group>
                                        </Stack>
                                    </Col>
                                    <Col className="col-12 col-xl-6 mb-3 mb-xl-0">
                                        <Stack gap={3}>                                        
                                            <Form.Group as={Row}>
                                                <Col className="col-3 d-flex align-items-center">
                                                    <Form.Label>MDU Unit Number</Form.Label>
                                                </Col>
                                                <Col className="col-9">
                                                    <Field isInvalid={errors.MDUUnitNumber } label="MDU Unit Number" name="MDUUnitNumber"  as={Form.Control}></Field>
                                                    {
                                                        errors.MDUUnitNumber &&
                                                        <small className="text-danger">{ errors.MDUUnitNumber }</small>
                                                    }
                                                </Col>
                                            </Form.Group>
                                        </Stack>
                                    </Col>                                               
                                </Row>
                                <Row>
                                    <Col className="col-12 col-xl-6 mb-3 mb-xl-0">
                                        <Stack gap={3}>                                        
                                            <Form.Group as={Row}>
                                                <Col className="col-3 d-flex align-items-center">
                                                    <Form.Label className="align-items-center">Township</Form.Label>
                                                </Col>
                                                <Col className="col-9">
                                                    <Field isInvalid={errors.Township } label="Township" name="Township"  as={Form.Control}></Field>
                                                            {
                                                                touched.Township && errors.Township &&
                                                                <small className="text-danger">{ errors.Township }</small>
                                                            }
                                                </Col>
                                            </Form.Group>
                                        </Stack>
                                    </Col>
                                    <Col className="col-12 col-xl-6 mb-3 mb-xl-0">
                                        <Stack gap={3}>                                        
                                            <Form.Group as={Row}>
                                                <Col className="col-3 d-flex align-items-center">
                                                    <Form.Label>POP</Form.Label>
                                                </Col>
                                                <Col className="col-9">
                                                    <Field isInvalid={errors.POP } label="Pop" name="POP" as={Form.Select}>                                                            
                                                        <option value="">Please select a POP</option>
                                                        <option value="UML">UML</option>
                                                        <option value="SYD">SYD</option>
                                                        <option value="VER">VER</option>
                                                        <option value="TON">TON</option>
                                                        <option value="MOU">MOU</option>
                                                        <option value="WAT">WAT</option>
                                                    </Field>
                                                    {
                                                        errors.POP &&
                                                        <small className="text-danger">{ errors.POP }</small>
                                                    }
                                                </Col>
                                            </Form.Group>
                                        </Stack>
                                    </Col>                         
                                </Row>                     
                                <Row>
                                    <Col className="col-12 col-xl-6 mb-3 mb-xl-0">
                                        <Stack gap={3}>                                        
                                            <Form.Group as={Row}>
                                                <Col className="col-3 d-flex align-items-center">
                                                    <Form.Label>Build</Form.Label>
                                                </Col>
                                                <Col className="col-9">
                                                    <Field label="Build" name="BuildId" as={Form.Select}>                                                            
                                                        <option value="">Please select a Build</option>
                                                        {
                                                            builds.map((build, index) => (
                                                                <option key={index} value={build.Id}>{build.Name}</option>
                                                            ))
                                                        }
                                                    </Field>
                                                </Col>
                                            </Form.Group>
                                        </Stack>
                                    </Col>                         
                                </Row>                     
                            </Stack>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="submit" className="btn btn-admin-primary">{saving ? "Saving..." : "Save"}</button>
                            <button type="button" className="btn btn-admin-secondary" onClick={() => onClose(false)}>Cancel</button>
                        </Modal.Footer>
                    </FormikForm>
                )}
            </Formik>
            <AlertModal                    
                show={showAlert}
                onClose={() => {
                    setShowAlert(false);
                    setAlertMessage("");
                    setSuccessMessage("");
                    onClose(true);
                }}
                message={alertMessage ? alertMessage : successMessage}
                success={successMessage}
            />
        </Modal>
    )
}