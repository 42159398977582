export const AddressSortColumns = {
    ERF: 0,
    StreetAddress: 1,
    MDUName: 2,
    MDUUnitNumber: 3,
    Township: 4,
    PropertyType: 5,
    POP: 6,
    InstallDate: 7,
    RadiusUsername: 8,
    InstallComplete: 9,
    Build: 10
}

export const SortDirections = {
    Ascending: 0,
    Descending: 1
}